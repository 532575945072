.footContain {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #092C4C;
    font-style: italic;
    position: relative;
    font-weight: 500;
    font-size: small;
}

.footContain>span {
    color: #092C4C;
    padding: 0px 20px 0px 20px;
}
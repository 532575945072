:root {
  --gradient1: linear-gradient(72.15deg, #0b3670 24.36%, #3665a4 77.16%);
  --gradient2: linear-gradient(72.15deg, #0b36701a 24.36%, #3665a41a 77.16%);
  --secondary: #44464c;
  --custom-primary: #27538d;
  --light-gray: #505259;
  --addLead: #707070;
}

* {
  margin: 0;
  font-family: "Roboto";
}

.scrollbar-hidden {
  max-height: 100vh;
  /* Adjust the height as needed */
  overflow-y: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */

  /* For WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-scrollbar {
  max-height: 230px;
  overflow-y: scroll;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

.primary1 {
  background: #27538d;
}

.pointer {
  cursor: pointer;
}

.loader {
  width: 38px;
  height: 38px;
  border: 5px solid #27538d;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-underline {
  text-decoration: underline;
  text-decoration-thickness: 1px; /* Thickness of the underline */
  text-decoration-color: #3771c3; /* Color of the underline */
  text-underline-offset: 3px; /* Offset from the text */
}

.custom-scrollbarX {
  max-height: 230px;
  overflow-x: scroll;
}

/* Scrollbar Track */
.custom-scrollbarX::-webkit-scrollbar {
  height: 4px; /* Since it's horizontal, this controls the thickness */
}

/* Scrollbar Thumb */
.custom-scrollbarX::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  border-radius: 8px;
}

/* Scrollbar Thumb on Hover */
.custom-scrollbarX::-webkit-scrollbar-thumb:hover {
  background-color: #bfbfbf;
}

/* Hide Scrollbar Buttons */
.custom-scrollbarX::-webkit-scrollbar-button {
  display: none;
}

.pointer {
  cursor: pointer;
}

.cardOrgin{
    width: 100%;
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.headsection1{
    display: flex;
    padding: 0px 10px 0px 10px;
    gap: 25px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #737373;
}

.headsection1 >img{
    width: 25px;
} 
.headsection2{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-left: 10px;
    color: #737373;
}

.headsection3{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    padding-left: 5px;
    color: #000000;
    font-weight: 600;
}
.headsection4{
    display: flex;
    margin-top: 10px;
    align-items: center;
    padding-left: 14px;
    color: #737373;
}
.headsection4 > span:first-child{
    color: #000000;
    font-weight: 600;
    font-size: 22px;
}
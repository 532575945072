.linearchart{
    display: flex;
    flex-direction: column;
    gap:35px;
    padding: 10px;
}
.piechartDes{
    display: flex;
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
}



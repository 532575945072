.navmain {
  width: 100%;
  height: 72px;
  background-color: #27538d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../assets/svg/navbarBlending.svg");
}
.navavatar {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}
.navlogo {
  padding-left: 20px;
}
.menuLogout {
  margin-top: 5px;
}
.logoutButton {
  display: flex;
  width: 100px;
  height: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
.logoutItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  gap: 5px;
}
.logoutButton:hover {
  background-color: #f6f6f6;
}

@media screen and (max-width: 1440px) and (max-height: 443px) {
  .navmain {
    background-image: url("../../assets/svg/navbarBlending.svg");
    /* Adjust size and position as needed */
    background-size: contain; /* Adjust this value as needed */
    background-position: center;
  }
}
/* @media screen and (max-width: 1024px) and (max-height: 443px) {
  .navmain {
    background-image: url("../../assets/navbarBlending.svg");
    background-size: contain;
    background-position: center;
  }
} */

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    color: #707070 !important; /* Set default color */
  }

/* Style for the active state */
.active .MuiListItemButton-root {
  color: #27538d; /* White text color for active state */
}

.active .MuiListItemIcon-root {
  color: #27538d; /* White color for active icon */
}

/* Optionally, you can remove the default background color for active state */
.active .MuiListItemButton-root {
  background-color: transparent; /* Remove the background color */
}

/* Center text below the icon when drawer is open */
.MuiListItemButton-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MuiListItemText-root {
  display: flex;
  justify-content: center;
}

.faqitems {
    display: flex;
    align-items: self-end;
    justify-content: center;
    height: 200px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
  }
  .faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .questionMark {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3vh;
  }

/* .main {
  width: 100%;
  background-color: "#ffffff";
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  width: 100%;
}

.menuContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
  transition: width 0.3s;
}

.contentContainer {
  margin-left: 7.1%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  transition: margin-left 0.3s;
}

.contentContainerCollapsed {
  margin-left: 5.1%;
} */

.main {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  width: 100%;
}

.menuContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  /* z-index: 1000; */
  overflow-y: auto;
  transition: width 0.3s;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  transition: margin-left 0.3s, margin-right 0.3s;
  margin-left: 7.1%; /* Default for larger screens */
}

.contentContainerCollapsed {
  margin-left: 5.1%; /* Default for larger screens */
}

/* Adjustments for screens between 1500px and 2560px */
@media (min-width: 2000px) and (max-width: 2560px) {
  .contentContainer {
    margin-left: 4.2%; /* Adjust this value based on your design */
  }

  .contentContainerCollapsed {
    margin-left: 3.2%; /* Adjust accordingly */
  }
}

/* Adjustments for screens smaller than 1440px */
@media (max-width: 1440px) {
  .contentContainer {
    margin-left: 7.2%; /* Adjust this value based on the actual width of the menu */
  }

  .contentContainerCollapsed {
    margin-left: 5.2%; /* Adjust accordingly */
  }
}

/* Further adjustments for smaller screens */
@media (max-width: 1024px) {
  .contentContainer {
    margin-left: 9.4%;
  }

  .contentContainerCollapsed {
    margin-left: 7.2%;
  }
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .contentContainer {
    margin-left: 14.4%;
    margin-right: 1.8%;
  }

  .contentContainerCollapsed {
    margin-left: 10.4%;
  }
}

@media (max-width: 600px) {
  .contentContainerCollapsed {
    margin-left: 6.4%;
  }
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,800');

.error-container {
  text-align: center;
  font-size: 180px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 20px 15px;
}
.error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #FFB485;
}
.error-container > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.error-container > span:nth-of-type(1) {
  color: #D1F2A5;
  animation: colordancing 4s infinite;
}
.error-container > span:nth-of-type(3) {
  color: #F56991;
  animation: colordancing2 4s infinite;
}
.error-container > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}
.error-container > span:nth-of-type(2):before,
.error-container > span:nth-of-type(2):after {
  border-radius: 0%;
  content:"";
  position: absolute;
  top: 0; left: 0;
  width: inherit; height: inherit;
  border-radius: 999px;
  box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
    inset 0 30px 0 rgba(239, 250, 180, 0.4),
    inset -30px 0 0 rgba(255, 196, 140, 0.4),  
    inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  animation: shadowsdancing 4s infinite;
}
.error-container > span:nth-of-type(2):before {
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}

.screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}
@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
    inset 0 30px 0 rgba(239, 250, 180, 0.4),
    inset -30px 0 0 rgba(255, 196, 140, 0.4),  
    inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
    inset 0 30px 0 rgba(209, 242, 165, 0.4),
    inset -30px 0 0 rgba(239, 250, 180, 0.4),  
    inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
    box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
    inset 0 30px 0 rgba(245, 105, 145, 0.4),
    inset -30px 0 0 rgba(209, 242, 165, 0.4),  
    inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
    box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
    inset 0 30px 0 rgba(255, 196, 140, 0.4),
    inset -30px 0 0 rgba(245, 105, 145, 0.4),  
    inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
    inset 0 30px 0 rgba(239, 250, 180, 0.4),
    inset -30px 0 0 rgba(255, 196, 140, 0.4),  
    inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@keyframes colordancing {
  0% {
    color: #D1F2A5;
  }
  25% {
    color: #F56991;
  }
  50% {
    color: #FFC48C;
  }
  75% {
    color: #EFFAB4;
  }
  100% {
    color: #D1F2A5;
  }
}
@keyframes colordancing2 {
  0% {
    color: #FFC48C;
  }
  25% {
    color: #EFFAB4;
  }
  50% {
    color: #D1F2A5;
  }
  75% {
    color: #F56991;
  }
  100% {
    color: #FFC48C;
  }
}

* {
  font-family: 'PT Sans Caption', sans-serif, 'arial', 'Times New Roman';
}

/* Error Page */
.error .clip .shadow {
  height: 180px;  /*Contrall*/
}
.error .clip:nth-of-type(2) .shadow {
  width: 130px;   /*Contrall play with javascript*/ 
}
.error .clip:nth-of-type(1) .shadow, .error .clip:nth-of-type(3) .shadow {
  width: 250px; /*Contrall*/
}
.error .digit {
  width: 150px;   /*Contrall*/
  height: 150px;  /*Contrall*/
  line-height: 150px; /*Contrall*/
  font-size: 120px;
  font-weight: bold;
}
.error h2 { /*Contrall*/
  font-size: 32px;
}
.error .msg { /*Contrall*/
  top: -190px;
  left: 30%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 32px;
}
.error span.triangle { /*Contrall*/
  top: 70%;
  right: 0%;
  border-left: 20px solid #535353;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

/* Error Page */
@media(max-width: 767px) {
  /* Error Page */
  .error .clip .shadow {
    height: 100px;  /*Contrall*/
  }
  .error .clip:nth-of-type(2) .shadow {
    width: 80px;   /*Contrall play with javascript*/ 
  }
  .error .clip:nth-of-type(1) .shadow, .error .clip:nth-of-type(3) .shadow {
    width: 100px; /*Contrall*/
  }
  .error .digit {
    width: 80px;   /*Contrall*/
    height: 80px;  /*Contrall*/
    line-height: 80px; /*Contrall*/
    font-size: 52px;
  }
  .error h2 { /*Contrall*/
    font-size: 24px;
  }
  .error .msg { /*Contrall*/
    top: -110px;
    left: 15%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
  .error span.triangle { /*Contrall*/
    top: 70%;
    right: -3%;
    border-left: 10px solid #535353;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
  .error .container-error-404 {
    height: 150px;
  }
  /* Error Page */
}

/*--------------------------------------------Framework --------------------------------*/

.overlay { 
  position: relative; 
  z-index: 20; 
} /*done*/
.ground-color { 
  background: white; 
}  /*done*/
.item-bg-color { 
  background: #EAEAEA 
} /*done*/

/* Padding Section*/
.padding-top { 
  padding-top: 10px; 
} /*done*/
.padding-bottom { 
  padding-bottom: 10px; 
}   /*done*/
.padding-vertical { 
  padding-top: 10px; 
  padding-bottom: 10px; 
}
.padding-horizontal { 
  padding-left: 10px; 
  padding-right: 10px; 
}
.padding-all { 
  padding: 10px; 
}   /*done*/

.no-padding-left { 
  padding-left: 0px; 
}    /*done*/
.no-padding-right { 
  padding-right: 0px; 
}   /*done*/
.no-vertical-padding { 
  padding-top: 0px; 
  padding-bottom: 0px; 
}
.no-horizontal-padding { 
  padding-left: 0px; 
  padding-right: 0px; 
}
.no-padding { 
  padding: 0px; 
}   /*done*/
/* Padding Section*/

/* Margin section */
.margin-top { 
  margin-top: 10px; 
}   /*done*/
.margin-bottom { 
  margin-bottom: 10px; 
} /*done*/
.margin-right { 
  margin-right: 10px; 
} /*done*/
.margin-left { 
  margin-left: 10px; 
} /*done*/
.margin-horizontal { 
  margin-left: 10px; 
  margin-right: 10px; 
} /*done*/
.margin-vertical { 
  margin-top: 10px; 
  margin-bottom: 10px; 
} /*done*/
.margin-all { 
  margin: 10px; 
}   /*done*/
.no-margin { 
  margin: 0px; 
}   /*done*/

.no-vertical-margin { 
  margin-top: 0px; 
  margin-bottom: 0px; 
}
.no-horizontal-margin { 
  margin-left: 0px; 
  margin-right: 0px; 
}

.inside-col-shrink { 
  margin: 0px 20px; 
}    /*done - For the inside sections that has also Title section*/ 
/* Margin section */

hr {
  margin: 0px; 
  padding: 0px; 
  border-top: 1px dashed #999; 
}
/*--------------------------------------------FrameWork------------------------*/

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../../assets/svg/calendar.svg") no-repeat;
  border-width: thin;
  cursor: pointer;
  background-position: center;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  color: rgb(40, 41, 45);
  opacity: 1;
  background: url("../../assets/svg/downArrow.svg") no-repeat;
  cursor: pointer;
  background-position: center;
}

input::placeholder {
  color: var(--secondary) !important;
}

.date-picker {
  position: relative;
}

.date-picker::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.date-picker::-webkit-clear-button {
  display: none;
}

.date-picker::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

.date-picker::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
}
